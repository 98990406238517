<template>
  <div>
    <h3 ref="def">
      Circumference
    </h3>
    <p>
      Let's say you're given a circle.
      Now, imaging walking along the edge of this circle until you get back to where you started.
      Then, the total distance traveled is defined as the circumference of the circle.
    </p>
    <h3 ref="formula">
      Circumference Formula
    </h3>
    <p>
      The circumference of a circle of radius R is given by
      $$L = 2 \times\pi \times R$$
      where \(\pi = 22/7\).
    </p>
    <h3 ref="pg">
      MagicGraph | Circumference of a circle
    </h3>
    <h5>To Get Started</h5>
    <p>
      This MagicGraph let's student learn about circumference of a circle using an interactive approach.
      You are given a coin of radius R. The radius of the coin can be set using the slider shown in the MagicGraph.
      Then, tap on the Go button to let the coin roll.
      The linear distance traveled by the coin until it has rolled a complete circle equals the circumference.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CircumferenceOfACircle',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Circumference of a Circle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Circumference', img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Formula', img:'/assets/number-1.svg', action: () => this.goto('formula')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Circumference of a Circle',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about square formula'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
